import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Schedule",
    component: () => import("../views/Schedule.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/hospital-select",
    name: "HospitalSelect",
    component: () => import("../views/HospitalSelect.vue"),
  },
  {
    path: "/create-reservation",
    name: "CreateReservation",
    component: () => import("../views/CreateReservation.vue"),
  },
  {
    path: "/edit-reservation/:id",
    name: "EditReservation",
    component: () => import("../views/EditReservation.vue"),
  },
  {
    path: "/reservation-management",
    name: "ReservationManagement",
    component: () => import("../views/ReservationManagement.vue"),
  },
  {
    path: "/course-setting",
    name: "CourseSetting",
    component: () => import("../views/CourseSetting.vue"),
  },
  {
    path: "/course-group-setting",
    name: "CourseGroupSetting",
    component: () => import("../views/CourseGroupSetting.vue"),
  },
  {
    path: "/mail-setting",
    name: "MailSetting",
    component: () => import("../views/MailSetting.vue"),
  },
  {
    path: "/notice-setting",
    name: "NoticeSetting",
    component: () => import("../views/NoticeSetting.vue"),
  },
  {
    path: "/notice-setting-edit/:id",
    name: "NoticeSettingEdit",
    component: () => import("../views/NoticeSettingEdit.vue"),
  },
  {
    path: "/announcements",
    name: "HospitalAnnouncementList",
    component: () => import("../views/HospitalAnnouncementList.vue"),
  },
  {
    path: "/announcements/:id",
    name: "HospitalAnnouncementDetail",
    component: () => import("../views/HospitalAnnouncementDetail.vue"),
  },
  {
    path: "/management/announcement-setting",
    name: "HospitalAnnouncementSetting",
    component: () => import("../views/HospitalAnnouncementSetting.vue"),
  },
  {
    path: "/management/announcement-setting-edit/:id",
    name: "HospitalAnnouncementSettingEdit",
    component: () => import("../views/HospitalAnnouncementSettingEdit.vue"),
  },
  {
    path: "/hospital/detail",
    name: "HospitalDetail",
    component: () => import("../views/HospitalDetail.vue"),
  },
  {
    path: "/hospital/edit",
    name: "HospitalEdit",
    component: () => import("../views/HospitalEdit.vue"),
  },
  {
    path: "/management/users",
    name: "UsersManagement",
    component: () => import("../views/UsersManagement.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../views/ChangePassword.vue"),
  },
  {
    path: "/change-password/complete",
    name: "ChangePasswordComplete",
    component: () => import("../views/ChangePasswordComplete.vue"),
  },

  {
    path: "/management/operators",
    name: "OperatorsManagement",
    component: () => import("../views/OperatorsManagement.vue"),
  },
  {
    path: "/management/operators/edit/:id",
    name: "OperatorEdit",
    component: () => import("../views/OperatorEdit.vue"),
  },
  {
    path: "/management/managers/edit/:id",
    name: "ManagerEdit",
    component: () => import("../views/OperatorEdit.vue"),
  },
  {
    path: "/management/facility",
    name: "FacilityManagement",
    component: () => import("../views/FacilityManagement.vue"),
  },
  {
    path: "/management/facility/:facility_id",
    name: "FacilityManagementDetail",
    component: () => import("../views/FacilityManagementDetail.vue"),
  },
  {
    path: "/management/admin-edit",
    name: "AdminManagement",
    component: () => import("../views/AdminManagement.vue"),
  },
  {
    path: "/management/facility/staff-edit/:facility_id",
    name: "FacilityManagementStaffEdit",
    component: () => import("../views/FacilityManagementStaffEdit.vue"),
  },
  {
    path: "/management/facility/create-hospital",
    name: "FacilityManagementHospitalCreate",
    component: () => import("../views/FacilityManagementHospital.vue"),
  },
  {
    path: "/management/facility/edit-hospital/:id",
    name: "FacilityManagementHospitalEdit",
    component: () => import("../views/FacilityManagementHospital.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/reset-password/complete",
    name: "ResetPasswordComplete",
    component: () => import("../views/ResetPasswordComplete.vue"),
  },
  {
    path: "/reset-password-send",
    name: "ResetPasswordSend",
    component: () => import("../views/ResetPasswordSend.vue"),
  },
  {
    path: "/reset-password-send/confirm",
    name: "ResetPasswordSendConfirmed",
    component: () => import("../views/ResetPasswordSendConfirmed.vue"),
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: () => import("../views/Sitemap.vue"),
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV !== "production") {
        // allow navigation
        next();
      } else {
        // redirect somewhere else
        next(false);
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior(to: Route, from: Route, savedPosition: any) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
